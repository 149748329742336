<template>
  <v-navigation-drawer
    :value="isAddNewUserActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 350 : '100%'"
    app
    @input="val => $emit('update:is-add-new-user-active', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center mb-4">
        <span class="font-weight-semibold text-base text--primary">Add New User</span>
        <v-spacer></v-spacer>
        <v-btn icon small @click="$emit('update:is-add-new-user-active', false)">
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-form ref="form" v-model="valid" @submit.prevent="onSubmit">
          <v-text-field
            v-model="userData.fullName"
            outlined
            dense
            :rules="[validators.required, validators.usernameValidator(userData.fullName, 6)]"
            label="Full Name"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <v-text-field
            v-model="userData.username"
            :rules="[validators.required, validators.usernameValidator(userData.username, 4)]"
            outlined
            dense
            label="User Name"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <v-text-field
            v-model="userData.email"
            :rules="[validators.required, validators.emailValidator]"
            outlined
            dense
            type="email"
            label="Email"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <v-text-field
            v-model="userData.phoneNumber"
            :rules="[validators.required, validators.lengthValidator(userData.phoneNumber, 10)]"
            outlined
            dense
            type="Number"
            label="Phone Number"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <v-text-field
            v-model="userData.password"
            label="Password"
            outlined
            dense
            hide-details="auto"
            type="password"
            :rules="[validators.required, validators.passwordValidator(userData.password)]"
            class="mb-6"
          ></v-text-field>

          <v-text-field
            v-model="userData.confirmPassword"
            label="Confirm Password"
            outlined
            dense
            hide-details="auto"
            type="password"
            :rules="[validators.required, validators.confirmedValidator(userData.confirmPassword, userData.password)]"
            class="mb-6"
          ></v-text-field>

          <v-select
            v-model="userData.role"
            :rules="[validators.required]"
            label="User Role"
            :items="RoleOptions"
            item-text="Name"
            item-value="NormalizedName"
            outlined
            dense
            hide-details="auto"
            class="mb-6"
          >
          </v-select>
          <select-restaurant
            class="mb-6"
            v-if="userData.role == 'CASHIER' || userData.role == 'OWNER'"
            v-model="userData.RestaurantId"
          />
          <select-bransh
            class="mb-6"
            v-if="userData.role == 'CASHIER' && userData.RestaurantId != undefined"
            v-model="userData.BranshId"
            :RestaurantId="userData.RestaurantId"
          />
          <v-select
            v-model="userData.Status"
            :rules="[validators.required]"
            label="Status"
            :items="$store.state.status['users']"
            item-text="title"
            item-value="value"
            outlined
            dense
            hide-details
            class="mb-6"
          ></v-select>
          <v-btn color="primary" class="me-3" type="submit"> Add </v-btn>
          <v-btn color="secondary" outlined type="reset" @click="resetuserData"> Cancel </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import SelectBransh from '@/views/Branch/SelectBransh.vue'
import SelectRestaurant from '@/views/Menu/Restaurant/SelectRestaurant.vue'
import { Register } from '@core/api/User'
import {
confirmedValidator,
emailValidator,
lengthValidator,
passwordValidator,
required,
usernameValidator
} from '@core/utils/validation'
import { mdiClose } from '@mdi/js'
import { onMounted, ref } from '@vue/composition-api'
export default {
  components: { SelectRestaurant, SelectBransh },
  model: {
    prop: 'isAddNewUserActive',
    event: 'update:is-add-new-user-active',
  },
  props: {
    isAddNewUserActive: {
      type: Boolean,
      required: true,
    },
    RoleOptions: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const blankUserData = {
      Id: undefined,
      fullName: '',
      username: '',
      phoneNumber: '',
      email: '',
      password: '',
      confirmPassword: '',
      role: null,
      Status: 'active',
      RestaurantId: undefined,
      BranshId: undefined,
    }

    const valid = ref(false)
    const form = ref(null)

    const validate = () => {
      form.value.validate()
    }

    const resetForm = () => {
      form.value.reset()
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
      resetForm()
      emit('update:is-add-new-user-active', false)
    }
    onMounted(() => {
      resetuserData()
      })
    const onSubmit = () => {
      if (valid.value) {
        Register(userData.value)
          .then(() => {
            emit('refetch-data')
            emit('update:is-add-new-user-active', false)
          })
          .catch(error => {
            console.log(error)
          })

        resetuserData()
      } else {
        validate()
      }
    }

    return {
      resetuserData,
      form,
      onSubmit,
      userData,
      valid,
      validate,
      // validation
      validators: {
        required,
        emailValidator,
        passwordValidator,
        confirmedValidator,
        usernameValidator,
        lengthValidator,
      },
      icons: {
        mdiClose,
      },
    }
  },
}
</script>

import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"value":_vm.isDialogDelete,"max-width":"500px"},on:{"input":function (val) { return _vm.$emit('update:is-dialog-delete', val); }}},[_c(VCard,[_c(VCardTitle,[_vm._v(" Are you sure you want to delete this user? ")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"error","outlined":""},on:{"click":_vm.closeDelete}},[_vm._v(" Cancel ")]),_c(VBtn,{attrs:{"color":"success"},on:{"click":_vm.deleteItemConfirm}},[_vm._v(" OK ")]),_c(VSpacer)],1),_c(VSnackbar,{attrs:{"light":_vm.$vuetify.theme.dark},model:{value:(_vm.isFormSubmittedSnackbarVisible),callback:function ($$v) {_vm.isFormSubmittedSnackbarVisible=$$v},expression:"isFormSubmittedSnackbarVisible"}},[_vm._v(" Can't Delete . ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-row>
    <v-col cols="12">
      <!-- app drawer -->
      <user-list-add-new
        v-model="isAddNewUserActive"
        :role-options="roleOptions"
        @refetch-data="fetchUsers"
      ></user-list-add-new>
      <user-delete @refetch-data="fetchUsers" :Id="userTemp.Id" v-model="isDialogDelete" />
      <user-edit @refetch-data="fetchUsers" :User="userTemp" v-model="isDialogEdit" />
      <!-- list filters -->
      <v-card>
        <v-card-title> Search &amp; Filter </v-card-title>
        <v-row class="px-2 ma-0">
          <v-col cols="12" sm="4">
            <!-- search -->
            <v-text-field v-model="searchQuery" placeholder="Search" outlined hide-details dense> </v-text-field>

            <v-spacer></v-spacer>
          </v-col>

          <!-- status filter -->
          <v-col cols="12" sm="4">
            <v-select
              v-model="statusFilter"
              label="Status"
              :items="$store.state.status['users']"
              item-text="title"
              item-value="value"
              outlined
              dense
              hide-details
              clearable
            ></v-select>
          </v-col>

          <!-- actions -->
          <v-col>
            <v-card-text class="d-flex align-center flex-wrap pb-0">
              <div class="d-flex align-center flex-wrap">
                <v-btn color="primary" class="mb-4 me-3" @click.stop="isAddNewUserActive = !isAddNewUserActive">
                  <v-icon>{{ icons.mdiPlus }}</v-icon>
                  <span>Add New User</span>
                </v-btn>

                <v-btn color="secondary" outlined class="mb-4">
                  <v-icon size="17" class="me-1">
                    {{ icons.mdiExportVariant }}
                  </v-icon>
                  <span>Export</span>
                </v-btn>
              </div>
            </v-card-text>
          </v-col>
        </v-row>
        <v-divider class="mt-4"></v-divider>
        <!-- table -->
        <v-data-table
          :headers="tableColumns"
          :items="userListTable"
          :options.sync="options"
          :server-items-length="totalUserListTable"
          :loading="loading"
        >
          <!-- name -->
          <template #[`item.FullName`]="{ item }">
            <div class="d-flex align-center">
              <v-menu bottom min-width="300px" rounded offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn icon x-large v-on="on">
                    <v-avatar
                      :color="item.avatar ? '' : 'primary'"
                      :class="item.avatar ? '' : 'v-avatar-light-bg primary--text'"
                      size="32"
                    >
                      <vc-image
                        :width="32"
                        :height="32"
                        :object-id="parseInt(item.PhoneNumber)"
                        table-name="User"
                        :label-text="item.FullName"
                      />
                    </v-avatar>
                  </v-btn>
                </template>
                <v-card>
                  <v-list-item-content class="justify-center">
                    <vc-upload-image
                      @refetch-data="fetchUsers"
                      :object-id="parseInt(item.PhoneNumber)"
                      table-name="User"
                    />
                  </v-list-item-content>
                </v-card>
              </v-menu>
              <div class="d-flex flex-column ms-3">
                {{ item.FullName }}
                <small>@{{ item.UserName }}</small>
              </div>
            </div>
          </template>

          <!-- role -->
          <template #[`item.role`]="{ item }">
            <div class="d-flex align-center">
              <v-avatar
                size="30"
                :color="resolveUserRoleVariant(item.role)"
                :class="`v-avatar-light-bg ${resolveUserRoleVariant(item.role)}--text me-3`"
              >
                <v-icon size="18" :color="resolveUserRoleVariant(item.role)">
                  {{ resolveUserRoleIcon(item.role) }}
                </v-icon>
              </v-avatar>
              <span class="text-capitalize">{{ item.role }}</span>
            </div>
          </template>
          <!-- status -->
          <template #[`item.Status`]="{ item }">
            <v-chip
              small
              :color="
                $store.state.status['users'].find(obj => {
                  return obj.value == item.Status
                }).variant || 'primary'
              "
              :class="`${
                $store.state.status['users'].find(obj => {
                  return obj.value == item.Status
                }).variant || 'primary'
              }--text`"
              class="v-chip-light-bg font-weight-semibold text-capitalize"
            >
              {{ item.Status }}
            </v-chip>
            <span v-if="item.Status == 'lockedout'">
            <v-btn icon color="info" @click="unlockDialog = true">
            <v-icon small>
                  {{ icons.mdiLockOpenOutline  }}
              </v-icon></v-btn>
                  <v-snackbar :timeout="5000" v-model="unlockDialog" vertical :light="$vuetify.theme.dark" top>
                    Unlock User: "{{ item.FullName }}" Account Name: {{ item.UserName }}
                    <template #action="{ attrs }">
                      <v-btn color="success" text v-bind="attrs" @click="Unlock(item.Id)"> Do it </v-btn>
                      <v-btn color="error" text v-bind="attrs" @click="unlockDialog = false"> Close </v-btn>
                    </template>
                  </v-snackbar>
                  </span>
          </template>
          <!-- actions -->
          <template #[`item.Actions`]="{ item }">
            <div class="demo-space-x">
              <v-btn icon color="error" @click.stop=";(isDialogDelete = !isDialogDelete), (userTemp = { ...item })">
                <v-icon small>
                  {{ icons.mdiDeleteOutline }}
                </v-icon>
              </v-btn>
               <v-btn icon color="primary" @click.stop=";(isDialogEdit = !isDialogEdit), (userTemp = { ...item })">
                  <v-icon small> {{ icons.mdiPencilOutline }} </v-icon>
                </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-card>
    </v-col></v-row
  >
</template>

<script>
// eslint-disable-next-line object-curly-newline
import VcImage from '@/components/vc-avatar/VcImage.vue'
import VcUploadImage from '@/components/vc-input/Vc-UploadImage.vue'
import store from '@/store'
import { UnLockout } from '@core/api/User'
import UserDelete from './UserDelete.vue'
import UserEdit from './UserEdit.vue'
// eslint-disable-next-line lines-around-comment

// sidebar
import userStoreModule from '@/store/app/user'
import {
mdiAccountOutline,
mdiDeleteOutline,
mdiDotsVertical,
mdiExportVariant, mdiLockOpenOutline, mdiPencilOutline, mdiPlus,
mdiSquareEditOutline
} from '@mdi/js'
import { onMounted, onUnmounted, ref } from '@vue/composition-api'
import UserListAddNew from './UserListAddNew.vue'
import useUsersList from './useUsersList'

export default {
  components: {
    UserListAddNew,
    VcImage,
    VcUploadImage,
    UserDelete,
    UserEdit,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'
    const isDialogDelete = ref(false)
    const isDialogEdit = ref(false)
    const userTemp = ref({ Id: '' })
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })
    onMounted(() => {
      fetchRole()
    })
    const {
      userListTable,
      tableColumns,
      searchQuery,
      roleFilter,
      statusFilter,
      totalUserListTable,
      loading,
      options,
      roleOptions,
      userTotalLocal,
      fetchRole,
      fetchUsers,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserTotalIcon,
    } = useUsersList()
    const unlockDialog = ref(false)
    const isAddNewUserActive = ref(false)
      const Unlock = Id => {
    UnLockout ({ UserId: Id }).then(response => {
      unlockDialog.value = false
      fetchUsers()
    })
    .catch(error => {
      console.log(error)
    })
  }
    return {
      isDialogEdit,
      unlockDialog,
      Unlock,
      userTemp,
      isDialogDelete,
      userListTable,
      tableColumns,
      searchQuery,
      roleFilter,
      totalUserListTable,
      statusFilter,
      loading,
      options,
      roleOptions,
      userTotalLocal,
      isAddNewUserActive,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserTotalIcon,
      fetchUsers,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiPencilOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
        mdiLockOpenOutline ,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>

<template>
  <v-dialog :value="isDialogEdit" max-width="750px" @input="val => $emit('update:is-dialog-edit', val)">
    <v-form ref="form" v-model="valid" @submit.prevent="onSubmit">
      <v-card>
        <v-card-title>
          <span class="headline">Edit User : {{ User.Id }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-text-field
              v-model="UserData.FullName"
              outlined
              dense
              :rules="[validators.required]"
              label="Full Name"
              placeholder="Full Name"
              hide-details="auto"
              class="mb-6"
            ></v-text-field>
            <v-select
              v-model="UserData.Status"
              :rules="[validators.required]"
              label="Status"
              :items="$store.state.status['users']"
              item-text="title"
              item-value="value"
              outlined
              dense
              hide-details
              class="mb-6"
            ></v-select>

            <v-text-field
              v-model="UserData.PhoneNumber"
              outlined
              dense
              label="Phone Number"
              placeholder="Phone Number"
              hide-details="auto"
              class="mb-6"
            ></v-text-field>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" outlined @click="close"> Cancel </v-btn>
          <v-btn color="success" type="submit"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { Edit } from '@core/api/User'
import { lengthValidator, required } from '@core/utils/validation'
import { mdiClockTimeFourOutline, mdiClose, mdiPlus } from '@mdi/js'
import { ref } from '@vue/composition-api'
export default {
  model: {
    prop: 'isDialogEdit',
    event: 'update:is-dialog-edit',
  },
  props: {
    isDialogEdit: {
      type: Boolean,
      required: true,
    },
    User: { type: Object, required: true },
  },
  watch: {
    User(New) {
      this.UserData = New
    },
  },
  setup(props, { emit }) {
    const blankData = {
      Id: undefined,
      FullName: '',
      PhoneNumber: '',
      Status: '',
    }
    const valid = ref(false)
    const form = ref(null)
    const validate = () => {
      form.value.validate()
    }
    const resetForm = () => {
      form.value.reset()
    }
    const UserData = ref(JSON.parse(JSON.stringify(blankData)))
    const resetUserData = () => {
      UserData.value = JSON.parse(JSON.stringify(blankData))
      resetForm()
      emit('update:is-dialog-edit', false)
    }
    const onSubmit = () => {
      if (valid.value) {
        Edit({ FullName: UserData.value.FullName, PhoneNumber: UserData.value.PhoneNumber, UserId: UserData.value.Id, Status: UserData.value.Status }).then(() => {
          emit('refetch-data')
          emit('update:is-dialog-edit', false)
        })
        resetUserData()
      } else {
        validate()
      }
    }
    const close = () => {
      resetUserData()
    }
    return {
      resetUserData,
      form,
      onSubmit,
      UserData,
      valid,
      validate,
      mdiPlus,
      close,
      // validation
      validators: { required, lengthValidator },
      icons: {
        mdiClose,
        mdiClockTimeFourOutline,
      },
    }
  },
}
</script>

import { Get as GetRoles } from '@core/api/Role'
import { GetUsers } from '@core/api/User'
import {
  mdiAccountCheckOutline,
  mdiAccountOutline,
  mdiAccountPlusOutline,
  mdiAccountRemoveOutline,
  mdiCogOutline,
  mdiDatabaseOutline,
  mdiDnsOutline,
  mdiPencilOutline
} from '@mdi/js'
import { ref, watch } from '@vue/composition-api'

export default function useUsersList() {
  const tableColumns = [
    /// { text: 'Id', value: 'Id' },
    { text: 'FULL NAME', value: 'FullName' },
    //{ text: 'EMAIL', value: 'Email' },
    { text: 'PHONE NUMBER', value: 'PhoneNumber', sortable: false },
    { text: 'ROLE', value: 'role' },
    { text: 'USER NAME', value: 'UserName', sortable: false },
    { text: 'STATUS', value: 'Status', sortable: false },
    { text: 'Actions', value: 'Actions', sortable: false },
  ]
  const userListTable = ref([])
  const roleOptions = ref([])
  const searchQuery = ref('')
  const roleFilter = ref(null)
  const statusFilter = ref(null)
  const totalUserListTable = ref(0)
  const loading = ref(false)
  const options = ref({
    sortBy: ['Id'],
    sortDesc: [false],
  })
  const userTotalLocal = ref([])

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'developer') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'error'

    return 'primary'
  }
  const fetchUsers = () => {
    GetUsers({
      any: searchQuery.value,
      status: statusFilter.value,
      //   role: roleFilter.value
    })
      .then(response => {
        // handle success

        userListTable.value = response

        totalUserListTable.value = response.lenght
        userTotalLocal.value = response.lenght

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }
  const fetchRole = () => {
    GetRoles()
      .then(response => {
        // handle success

        roleOptions.value = response
      })
      .catch(error => {
        // handle error
        console.log(error)
      })
  }

  watch([searchQuery, roleFilter, statusFilter, options], () => {
    loading.value = true
    // fetchRole()
    fetchUsers()
  })
  const resolveUserRoleIcon = role => {
    if (role === 'developer') return mdiAccountOutline
    if (role === 'author') return mdiCogOutline
    if (role === 'maintainer') return mdiDatabaseOutline
    if (role === 'editor') return mdiPencilOutline
    if (role === 'admin') return mdiDnsOutline

    return mdiAccountOutline
  }

  const resolveUserTotalIcon = total => {
    if (total === 'Total Users') return { icon: mdiAccountOutline, color: 'primary' }
    if (total === 'Paid Users') return { icon: mdiAccountPlusOutline, color: 'error' }
    if (total === 'Active Users') return { icon: mdiAccountCheckOutline, color: 'success' }
    if (total === 'Pending Users') return { icon: mdiAccountRemoveOutline, color: 'warning' }

    return { icon: mdiAccountOutline, color: 'primary' }
  }

  return {
    userListTable,
    tableColumns,
    searchQuery,
    roleFilter,
    statusFilter,
    totalUserListTable,
    loading,
    options,
    roleOptions,
    fetchRole,
    userTotalLocal,
    fetchUsers,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserTotalIcon,
  }
}
